
:root {

    body {
        overscroll-behavior: none;
        color: black;
        background-color: white;
        margin: 0;
        padding: 0;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    --bg-1: url("/assets/bg-1.png");

    // Core
    --core-blue: #4285F4;
    --core-green: #34A853;
    --core-orange: #FBBC04;
    --core-red: #EA4335;
    --core-disabled: lightgray;


    // FONTS
    .font-larger { font-size: larger; }
    .font-xx-large { font-size: xx-large; }
    font-size: 16px;
    font-family: "Open Sans";
    

    // Sizes
    --navbar-size: 5rem;    
    --action-nav-size: 12rem;
    --action-nav-width-size: 4rem;
    --main-panel-width: calc( 100vw - var(--navbar-size) );
    --main-panel-height: calc( 100vh - var(--action-nav-size) );

    // Navbar
    --transition-speed: 600ms;

    --active-overlay: rgba(20,144,255, 0.1);
    
    --border: .5px solid rgba(0, 0, 0, 0.2); // #474a4d;
    --border-radius: 8px;
  
    --speed: 500ms; 

    nav { 
        background: var( --b-1-bg );
        padding: 0px;
    }

    .disabled {
        color: var( --core-disabled );
    }

    ActionBar {

        grid-area: top-area;
        height: var(--action-nav-size);

        z-index: 2;
        background-color: var( --h-1-bg );
        color: var( --h-1-txt );
        box-shadow: 5px 0 10px rgba(0,0,0,.5);

        padding: 0 1rem;

        border-bottom: var(--border);
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        
        code {
            font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
        }
        
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            font-size: 16px;
        }
    
        a {
            color: var(--b-2-txt);
            text-decoration: none;
        }
    
        .actionBar {
            display: flex;
            align-self: center;
            justify-self: end;
        }
    
        .navbar-nav {
            max-width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
        }
    
        li .nav-item {
            width: calc(var(--action-nav-width-size) * 0.8);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .mat-checkbox .mat-checkbox-frame {
        background-color: var( --h-2-txt );
        color: var( --h-2-bg );
    }

    // buttons
    .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
        background-color: var( --h-2-bg );
    }
    .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
        color: var( --h-2-bg );
    }

    // Progress Bar
    .mat-progress-bar-fill::after {
        background-color: var( --h-2-bg );
    }
     .mat-progress-bar-buffer {
        background: var( --h-3-bg );
    }

    // Text Area
    .mat-input-element::-webkit-scrollbar {
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 10px;
        background: var( --scroll-bg );
    }
    .mat-input-element::-webkit-scrollbar-track {
        background: var( --scroll-bg );
        border-radius: 10px;
    }
    .mat-input-element::-webkit-scrollbar-thumb {
        background: var(--scroll-thumb );
        border-radius: 10px;
        background-clip: padding-box;  
    }
    .mat-input-element {
        scrollbar-width: 0.7rem;
        scrollbar-color: var(--scroll-thumb) var(--scroll-bg);
    }

    input.mat-input-element {
        color: var( --b-1-txt );
    }

    .mat-form-field.mat-focused .mat-form-field-label {
        color: var( --h-2-bg ) !important;
    }

    .mat-focused .mat-form-field-underline .mat-form-field-ripple{
        background-color: var( --h-2-bg ) !important;
    }

    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
        color: var( --a-1-bg );
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
        background-color: var( --h-1-bg ) !important;
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
        background-color: var( --h-1-bg );
    }

    .mat-icon-button svg {
        color: var( --a-1-bg );
    }

    .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
        background-color: var( --a-1-bg );
        height: 4px !important;
    }   

    .mat-tab-label {
        background: var(--h-3-bg);
        color: var( --h-3-txt );
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        filter: opacity(.9) grayscale(.3);
        border: solid 1px;
    }

    .mat-tab-label:hover {
        background: var(--h-3-bg);
        color: var(  --h-2-bg );
        filter: none;
    }

    .mat-tab-label-active {
        background: var( --h-3-bg );
        color: var(  --h-2-bg );
        opacity: 1;
        filter: none;
    }

    .lower-left-fab-dial {
        position: absolute;
        right: 20px;
        bottom: 20px;
        width: 60px;
        height: 60px;
        z-index: 1000;
        background: var( --b-1-bg );
        
        color: var(--a-1-bg);
        border: 0.5px solid var(--h-1-bg);
        filter: brightness(0.9); // opacity(0.9) 

        i {  font-size: 30px; }
    }

    .lower-left-fab-dial:hover {
        color: var( --a-1-bg ) !important;
    }
}

.bigBox {
    background:  var( --b-1-bg );
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.ltd-theme-blue::-webkit-scrollbar {
    display: none;
}

.navbar {
    top: 0;
    width: var(--navbar-size);
    height: 100vh;
}

.navbar:hover {
    width: 14rem;
}

.navbar:hover .link-text {
    display: inline;
}

.navbar:hover .logo svg {
    margin-left: 11rem;
    
}

.w, .i, .s, .p, .l, .t, .d {
    color: var( --a-1-bg );
}

.navbar:hover .logo-text {
    left: 0px;
}


.navbar-nav {
    flex-direction: column;
}

.nav-item.active {
    border-left: solid 10px var(--fa-primary);
    background: var(--h-3-bg);
    
    svg {
        width: 2rem;
        min-width: 2rem;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: calc( 1.5rem - 5px ); 
        margin-right: 1.5rem;
    }

    .nav-link {
        filter: none !important;
    }
}

.nav-link svg {
    width: 2rem;
    min-width: 2rem;
    margin: 0 1.5rem;
}

.mainView {
    width: var(--main-panel-width);
    height: var(--main-panel-height);
}

main {
    overflow: auto !important;
    height: var( --main-panel-height );
    grid-area: main-area;
    background-image: var( --bg-1 );
    background-repeat: repeat;
}

.mainWitHeader {
    display: grid;
    grid-template-areas: 
            "main-header"
            "main-content";
    grid-template-rows: 65px auto;
    justify-content: stretch;
    align-content: stretch;
    overflow: hidden;

    .main-header {
        grid-area: main-header;
    }

    .main-content {

        grid-area: main-content;
        overflow: auto;
        background: var( --bg-overlay-2 );

        .leaflet-bar {
            border: none;
            background-clip: padding-box;
        }
        .leaflet-control-zoom {
            a {
                border-radius: 100% !important;
                margin-top: 5px;
                line-height: 27px !important;
                background: var(--b-1-bg);
                color: var(--a-1-bg);
                border: 0.5px solid var(--h-1-bg);
                filter: brightness(0.9); // opacity(0.9) 
                z-index: 1;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
            }
            a:hover {
                box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
                filter:none;
            }
        }
        .leaflet-control-layers-expanded {
            background: var( --b-1-bg ) !important;
            color: var(--t-1-900);
            border: 0.5px solid var(--h-1-bg);
            filter: none !important;
            z-index: 1;
        }
        .leaflet-control-layers {
            border-radius: 14px;
            background: var(--b-1-bg);
            color: var(--t-1-900);
            border: 0.5px solid var(--h-1-bg);
            filter: none !important;
            z-index: 1;
        }
    }


    .main-content {
        scrollbar-width: 0.7rem;
        scrollbar-color: var(--scroll-thumb) var(--scroll-bg);
    }
    .main-content::-webkit-scrollbar {
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 10px;
        background: var( --scroll-bg );
    }
    .main-content::-webkit-scrollbar-track {
        background: var( --scroll-bg );
        border-radius: 10px;
    }
    .main-content::-webkit-scrollbar-thumb {
        background: var(--scroll-thumb );
        border-radius: 10px;
        background-clip: padding-box;  
    }
    z-index: 0;
}

header {
    background-color: var( --h-2-bg );
    color: var( --h-2-txt );
    font-size: 24px;
    font-weight: 600;

    display: grid;
    grid-template-areas: "icon title actions";
    grid-template-columns: 65px auto auto;
    justify-content: stretch;
    align-content: stretch;

    height: 65px;
    z-index: 1;

    box-shadow: 5px 0 10px rgba(0,0,0,.5);

    .title {
        grid-area: title;
        justify-self: start;
        align-self: center;
    }
    .actions {
        grid-area: actions;
        justify-self: end;
        align-self: center;
        margin-right: 20px;
        grid-gap: 10px;
        display: grid;
        grid-auto-flow: column;
    }
    .icon {
        grid-area: icon;
        align-self: center;
        justify-self: center;
        font-size: 30px;
        color: var(--h-1-txt);
    }
}

main::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.theRight {
    button {
        color: var( --t-a-400 );
        background: var( --h-2-bg );
        // background: rgba(255,255,255,.9);
        margin-left: 10px;

        // new
        z-index: 1;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        border: transparent;        
    }
    button:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }

    .button-disabled, .button-disabled:hover {
        box-shadow: none !important;
        filter: grayscale(1) opacity( .5 )  !important;
    }
}

.fab-dial {
    button {
        background: var( --h-2-bg) !important;
        z-index: 1;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        border: transparent;
        color: var(--a-1-bg) !important;
        filter: brightness(0.9);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
    button:hover {
        filter: none;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
}

.navbar {
    position: fixed;
    background-color: var( --nav-1-bg );
    // background-color: var(--b-1-bg);
    transition: width 600ms ease;
    overflow: auto;
    z-index: 10000;
    box-shadow: 1px -1px 2px 0px rgba(0,0,0,.2);
    grid-area: nav-area;
     
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.navbar::-webkit-scrollbar {
    display: none;
}

.navbar-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    height: 100%;
}

.nav-item {
    width: 100%;
}

.nav-item:last-child {
    margin-top: auto;
}

.nav-link {
    display: flex;
    align-items: center;
    height: 65px !important;

    color: var(--nav-1-txt);
    // color: var(--b-2-txt);
    text-decoration: none;
    
    filter: opacity(0.7);
    transition: var(--transition-speed);
}

.nav-link:hover {
    filter: grayscale(0%) opacity(1);
    background: var(--nav-a-bg);
    color: var(--nav-a-txt);
}

.link-text {
    display: none;
    margin-left: 1rem;
}

.fa-primary {
    color: var( --fa-primary );
}

.fa-secondary {
    color: var( --fa-secondary );
}

.fa-primary,
.fa-secondary {
    transition: var(--transition-speed);
}

i {
    font-size: x-large;
}


.logo {
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
    background: var(--h-3-bg);
    width: 100%;
    font-size: 20px;
    width: 100%;
     
    .nav-link {
        filter: none !important;
    }
}

.logo svg {
    transform: rotate(0deg);
    transition: var(--transition-speed);
}

.logo-text {
    display: inline;
    position: absolute;
    left: -999px;
    transition: var(--transition-speed);
    background: var( --h-1-bg );
    border-radius: 50px;
    filter: none;
    padding: 5px 10px 5px 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.fg-red {
    color: red !important;
}

.fg-red-orange {
    color: orangered !important;
}

.fg-orange {
    color: orange !important;
}

.fg-yellow {
    color: #ccbb11 !important;
}

.fg-yellow-green {
    color: yellowgreen !important;
}

.fg-green {
    color: rgba(27,181,27, .92) !important;
}

.menu-i {
    font-size: 30px;
    padding-right: 10px;
    color: var(--h-2-bg);
    vertical-align: middle;
}

.map-card {
    display: grid;
    background: var(--b-1-bg);
    color: var(--b-1-txt);
    font-size: 14px;

    grid-template-areas:
        "map-head"
        "map-name"
        "map-detail"
        "map-foot";
    grid-template-rows: 20px auto auto 20px;
    grid-template-columns: auto;
    border-radius: 20px;
    justify-content: stretch;
    align-content: stretch;

    .map-name { grid-area: map-name; font-size: 18px; padding: 10px 20px 10px 20px; }
    .map-detail { grid-area: map-detail; padding: 0px 10px 10px 10px; }

    .map-head { 
        grid-area: map-head; 
        background: var( --h-1-bg );
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
    }

    .map-foot { 
        grid-area: map-foot; 
        background: var( --h-1-bg );
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
    }
}

.navbar:hover .logo svg {
    transform: rotate(-180deg);
}

.theme-icon {
    display: none;
}

.droplet {
    --theme_id: 'droplet';
}
.droplet #droplet {
    display: block;
}


.night {
    --theme_id: 'night';
}
.night #night {
    display: block;
}

.sunburst {
    --theme_id: 'sunburst';
}
.sunburst #sunburst {
    display: block;
}

.vampire {
    --theme_id: 'vampire';
}
.vampire #vampire {
    display: block;
}

.starwars {
    --theme_id: 'starwars';
}
.starwars #starwars {
    display: block;
}


.coffee {
    --theme_id: 'coffee';
}
.coffee #coffee {
    display: block;
}


.trees {
    --theme_id: 'trees';
}
.trees #trees {
    display: block;
}

