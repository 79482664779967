
@import '~/../../node_modules/@mdi/font/scss/materialdesignicons.scss';
@import './app/shared/styles/ltd-main.scss';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@angular/material/theming';

@include mat-core();
$primary: mat-palette($mat-blue);
$accent:  mat-palette($mat-light-blue, 600, 300, 600);
$warn:    mat-palette($mat-deep-orange, 300, 500, 800);

$theme:   mat-light-theme($primary,$accent,$warn);
@include angular-material-theme($theme);


@font-face {
    font-family: 'inconsolata';
    font-style: normal;
    font-weight: 400;
    src: url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200&display=swap') format('woff2');
}

@font-face {
    font-family: 'sofia';
    font-style: normal;
    font-weight: 400;
    src: url('https://fonts.googleapis.com/css2?family=Princess+Sofia&display=swap') format('woff2');
}

@font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    src: url('https://fonts.googleapis.com/css2?family=Roboto&display=swap') format('woff2');
}

@font-face {
    font-family: 'slabo';
    font-style: normal;
    font-weight: 400;
    src: url('https://fonts.googleapis.com/css2?family=Slabo+27px&display=swap') format('woff2');
}

@font-face {
    font-family: 'ubuntu';
    font-style: normal;
    font-weight: 400;
    src: url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap') format('woff2');
}

@font-face {
    font-family: 'Amatic SC', cursive;;
    font-style: normal;
    font-weight: 400;
    src: url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap') format('woff2');
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v34/2fcrYFNaTjcS6g4U3t-Y5ZjZjT5FdEJ140U2DJYC3mY.woff2) format('woff2');
}



.BM {
    mat-dialog-container {
        padding: 0px !important;
        background: transparent  !important;
        box-shadow: none !important;
        overscroll-behavior: none !important;
    }
}


// 
// Quill formatting
//

// Font List
.ql-font-sofia { font-family: "sofia"; }
.ql-font-slabo { font-family: "slabo"; }
.ql-font-amatic { font-family: "amatic sc"; }
.ql-font-roboto { font-family: "roboto"; }
.ql-font-inconsolata { font-family: "inconsolata"; }
.ql-font-ubuntu { font-family: "ubuntu"; }

// Font Menu
.ql-font span[data-label="Sofia"]::before {
    font-family: "sofia";
    font-size: 1.5em;
}
.ql-font span[data-label="Slabo"]::before {
    font-family: "slabo";
    font-size: 1.5em;
}
.ql-font span[data-label="Roboto"]::before {
    font-family: "roboto";
    font-size: 1.5em;
}
.ql-font span[data-label="Inconsolata"]::before {
    font-family: "inconsolata";
    font-size: 1.5em;
}
.ql-font span[data-label="Ubuntu"]::before {
    font-family: "ubuntu";
    font-size: 1.5em;
}
.ql-font span[data-label="Amatic SC"]::before {
    font-family: "amatic sc";
    font-size: 1.5em;
}

// Text Format
.ql-bubble {
    .ql-editor {

        h1 {
            // margin: 20px 0px 10px 0px !important;
            color: red !important;
            font-family: "amatic sc";
            font-size: 4em !important;
        }

        h2 {
            // margin: 20px 0px 10px 0px !important;
            color: blue !important;
            font-family: "amatic sc";
            font-size: 3em !important;
        }

        h3 {
            // margin: 20px 0px 10px 0px !important;
            color: green !important;
            font-family: "amatic sc";
            font-size: 2em !important;
        }

        p, ol, ul, pre, blockquote, h1, h2, h3, h4, h5, h6 {
            margin: 0;
            padding-bottom: 5px 0px 10px 0px;
            counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        }
    }
} 
